import React from 'react'
import { useQueries } from '@tanstack/react-query'
import { InclusionScoreAnalysisCard, InclusionScoreAnalysisCardSkeleton } from '@diversioteam/diversio-ds'
import mixpanel from 'mixpanel-browser'
import { parseYearQuarter, stringifyQueryParams } from 'utils'
import { getInclusionHeatmap } from 'sagas/network'

import { Queries } from 'api/queries.enum'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { useGetPastSurveys } from 'hooks/useGetPastSurveys'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { useGetActiveCompanyInformation } from 'hooks/useGetActiveCompany'
import { useTooltips } from 'hooks/tooltips/useTooltips'
import { TooltipId } from 'utils/tooltips.enum'
import TAXONOMIES from 'utils/taxonomies'

import { parseData } from './inclusionScore.helpers'

const InclusionScoreWithoutEB = () => {
    const queryPastSurveys = useGetPastSurveys()
    const { survey } = useSurveys()
    const { companyInformation } = useGetActiveCompanyInformation()
    const { getOnboardingButtonProps } = useTooltips()

    const queriesScoreCard = useQueries({
        queries: [...(queryPastSurveys.data?.pastSurveys || [])]
            .reverse()
            .map(({ year, quarter, yearQuarterLabel }) => {
                const params = {
                    year: parseYearQuarter({ name: yearQuarterLabel, year, quarter }),
                }

                return {
                    queryKey: [Queries.getInclusionHeatmap, params],
                    queryFn: () => getInclusionHeatmap(stringifyQueryParams(params)),
                    keepPreviousData: true,
                    ...preventRefetchQueryOptions,
                }
            }),
    })

    const onboardingButtonProps = getOnboardingButtonProps(TooltipId.HomeInclusionScore)

    if (queryPastSurveys.isLoading || queriesScoreCard.some(({ isLoading }) => isLoading) || !survey) {
        return <InclusionScoreAnalysisCardSkeleton data-testid="inclusion-score-skeleton" />
    }

    if (!queryPastSurveys.data || queriesScoreCard.some(({ data }) => !data)) {
        return null
    }

    const parsedData = parseData({
        pastSurveysData: queryPastSurveys.data,
        inclusionHeatMapData: queriesScoreCard.map(({ data }) => data),
    })

    return (
        <InclusionScoreAnalysisCard
            title="Inclusion score"
            isRadialBarChartAnimated={true}
            results={parsedData}
            selectedSurvey={{
                ...survey,
                yearQuarterLabel: survey.name,
            }}
            companyName={companyInformation.name}
            onTrigger={(trigger) => mixpanel.track(TAXONOMIES.HOME_INTERACT_INCLUSION_SCORE, { trigger })}
            onboardingButtonProps={onboardingButtonProps}
            data-joyride="inclusion-score"
            data-testid="inclusion-score"
        />
    )
}

export const InclusionScore = withErrorBoundary(InclusionScoreWithoutEB, {})
