import React from 'react'
import { DiversityChartCard, DiversityChartCardSkeleton } from '@diversioteam/diversio-ds'

import { useGetDiversityBreakdownByRole } from 'hooks/useGetDiversityBreakdownByRole'
import { useTooltips } from 'hooks/tooltips/useTooltips'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { TooltipId } from 'utils/tooltips.enum'

export const DiversityBreakdownByRoleWithoutEB = () => {
    const { data, isLoading } = useGetDiversityBreakdownByRole()
    const { getOnboardingButtonProps } = useTooltips()

    const onboardingButtonProps = getOnboardingButtonProps(TooltipId.HomeDiversityBreakdownByRole)

    if (isLoading) {
        return <DiversityChartCardSkeleton data-testid="diversity-breakdown-by-role-skeleton" />
    }

    if (!data || data.results.length === 0) {
        return null
    }

    return (
        <DiversityChartCard
            enableAnimation
            data={data.results}
            category={data.category}
            onboardingButtonProps={onboardingButtonProps}
            data-testid="diversity-breakdown-by-role"
        />
    )
}

export const DiversityBreakdownByRole = withErrorBoundary(DiversityBreakdownByRoleWithoutEB, {})
