import React from 'react'
import { InclusionInsightsCard, InclusionInsightsCardSkeleton } from '@diversioteam/diversio-ds'
import { Link } from 'react-router-dom'
import { useMediaQuery } from '@material-ui/core'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useGetInclusionInsights } from 'hooks/useGetInclusionInsights'
import { useTooltips } from 'hooks/tooltips/useTooltips'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { AppRoute } from 'routing/AppRoute.enum'
import { breakpoints } from 'styles/theme/mediaQueries'
import { TooltipId } from 'utils/tooltips.enum'

const InclusionInsightsWithoutEB = () => {
    const matches = useMediaQuery(`(min-width:${breakpoints.lg}px)`)
    const { data, isLoading } = useGetInclusionInsights()
    const { getOnboardingButtonProps } = useTooltips()

    const hasNoData = !data
    const hasNoDataItems = data?.positive.length === 0 && data?.negative.length === 0

    const onboardingButtonProps = getOnboardingButtonProps(TooltipId.HomeInclusionInsights)

    if (isLoading) {
        return <InclusionInsightsCardSkeleton data-testid="inclusion-insights-skeleton" />
    }

    if (hasNoData || hasNoDataItems) {
        return null
    }

    return (
        <InclusionInsightsCard
            {...data}
            title="Inclusion Insights"
            titleWrapperProps={{
                component: Link,
                to: generateCompanyURI(`${AppRoute.AnalyzeInclusion}/target_groups`),
            }}
            actionButtonProps={{
                component: Link,
                to: generateCompanyURI(AppRoute.SolutionGoals),
            }}
            onboardingButtonProps={onboardingButtonProps}
            columns={matches ? 2 : 1}
        />
    )
}

export const InclusionInsights = withErrorBoundary(InclusionInsightsWithoutEB, {})
