import React from 'react'
import { NoResults } from '@diversioteam/diversio-ds'

import { useGetBespokeFreeTexts } from 'hooks/freeTexts/useGetBespokeFreeTexts'

import { useFreeTextsState } from './../hooks/useFreeTextsState'
import { CustomCategoryProps } from './customCategory.types'
import { FreeTextAccordion } from './FreeTextAccordion'
import { FreeTextAccordionSkeleton } from './FreeTextAccordionSkeleton'
import * as S from './../freeTexts.styles'

export const CustomCategory = ({ category }: CustomCategoryProps) => {
    const { debouncedSearchValue } = useFreeTextsState()

    const queryBespokeFreeTexts = useGetBespokeFreeTexts({
        search: debouncedSearchValue,
        category,
    })

    const responses = queryBespokeFreeTexts.data || []

    const responseSkeletonComponent = <FreeTextAccordionSkeleton />

    const responsesComponent =
        responses.length === 0 ? (
            <NoResults />
        ) : (
            responses.map(({ question, count, uuid }, index) => (
                <FreeTextAccordion
                    key={`${uuid}${debouncedSearchValue}`}
                    text={question}
                    bespokeQuestion={uuid}
                    category={category}
                    numberOfResponses={count}
                    defaultExpanded={index === 0}
                />
            ))
        )

    return (
        <S.Category isLoading={queryBespokeFreeTexts.isLoading} data-testid={`free-texts-${category}`}>
            {queryBespokeFreeTexts.isLoading ? responseSkeletonComponent : responsesComponent}
        </S.Category>
    )
}
