import React, { useCallback, useEffect, useMemo, useState } from 'react'
import mixpanel from 'mixpanel-browser'
import { KpiKey } from '@diversioteam/diversio-ds'

import { useDebounce } from 'hooks/useDebounce'
import { useExportBespokeFreeTexts } from 'hooks/freeTexts/export/useExportBespokeFreeTexts'
import { useExportFreeTexts } from 'hooks/freeTexts/export/useExportFreeTexts'
import TAXONOMIES from 'utils/taxonomies'

import { FreeTextCategory } from './../freeTexts.types'
import { FreeTextsStateContext } from './FreeTextsStateContext'
import { FreeTextsStateContextControllerProps } from './FreeTextsStateContextController.types'

export const FreeTextsStateContextController = ({ children }: FreeTextsStateContextControllerProps) => {
    const [searchValue, setSearchValue] = useState('')
    const [activeInclusionKpi, setActiveInclusionKpi] = useState<KpiKey>('ALL')
    const [selectedInclusionPainPoint, setSelectedInclusionPainPoint] = useState<string | null>(null)
    const debouncedSearchValue = useDebounce(searchValue, 500)

    const mutationExportInclusionFreeTexts = useExportFreeTexts({
        search: debouncedSearchValue,
        pain_point: selectedInclusionPainPoint || undefined,
        kpi: activeInclusionKpi !== 'ALL' ? activeInclusionKpi : undefined,
    })

    const mutationExportBespokeFreeTexts = useExportBespokeFreeTexts({
        search: debouncedSearchValue,
        category: 'bespoke',
    })

    const mutationExportEngagementFreeTexts = useExportBespokeFreeTexts({
        search: debouncedSearchValue,
        category: 'engagement',
    })

    useEffect(() => {
        setSelectedInclusionPainPoint(null)
    }, [activeInclusionKpi])

    const handleChangeSearchValue = (searchValue: string) => {
        setSearchValue(searchValue)
    }

    const handleChangeActiveInclusionKpi = (inclusionKpi: KpiKey) => {
        if (inclusionKpi !== 'ALL') {
            mixpanel.track(TAXONOMIES.ANALYZE_INCLUSION_FREE_TEXT_METRIC_SELECTED, {
                inclusionKpi,
            })
        }

        setActiveInclusionKpi(inclusionKpi)
    }

    const handleChangeSelectedInclusionPainPoint = (painPoint: string | null) => {
        if (painPoint !== null) {
            mixpanel.track(TAXONOMIES.ANALYZE_INCLUSION_FREE_TEXT_PAIN_POINT_SELECTED, {
                painPoint,
            })
        }

        setSelectedInclusionPainPoint(painPoint)
    }

    const handleDownload = useCallback(
        (freeTextCategory: FreeTextCategory) => {
            const mutations = {
                [FreeTextCategory.Inclusion]: mutationExportInclusionFreeTexts,
                [FreeTextCategory.Bespoke]: mutationExportBespokeFreeTexts,
                [FreeTextCategory.Engagement]: mutationExportEngagementFreeTexts,
            }

            const mutation = mutations[freeTextCategory]

            return mutation?.mutate()
        },
        [mutationExportBespokeFreeTexts, mutationExportEngagementFreeTexts, mutationExportInclusionFreeTexts],
    )

    const contextValue = useMemo(
        () => ({
            searchValue,
            debouncedSearchValue: debouncedSearchValue,
            onChangeSearchValue: handleChangeSearchValue,
            activeInclusionKpi,
            onChangeActiveInclusionKpi: handleChangeActiveInclusionKpi,
            selectedInclusionPainPoint,
            onChangeSelectedInclusionPainPoint: handleChangeSelectedInclusionPainPoint,
            onDownload: handleDownload,
        }),
        [activeInclusionKpi, debouncedSearchValue, handleDownload, searchValue, selectedInclusionPainPoint],
    )

    return <FreeTextsStateContext.Provider value={contextValue}>{children}</FreeTextsStateContext.Provider>
}
