import parser from 'html-react-parser'
import { OnboardingButtonProps } from '@diversioteam/diversio-ds'

import { useGetTooltips } from './useGetTooltips'
import { useVoteTooltip } from './useVoteTooltip'

export const useTooltips = () => {
    const tooltipsQuery = useGetTooltips()
    const voteTooltipMutation = useVoteTooltip()

    const getOnboardingButtonProps = (componentId: string) => {
        const tooltip = tooltipsQuery.data?.find(({ tooltipId }) => tooltipId === componentId)

        const onboardingButtonProps: OnboardingButtonProps | undefined =
            tooltip && tooltip.contents.length > 0
                ? {
                      steps: tooltip.contents.map(({ content, title, youtubeVideoId, thumbnail }) => ({
                          title,
                          description: parser(content),
                          videoId: youtubeVideoId,
                          thumbnail: thumbnail || '',
                      })),
                      vote: tooltip.liked === null ? undefined : tooltip.liked,
                      onVote: (value: boolean | null) => {
                          const id = tooltip.uuid

                          voteTooltipMutation.mutate({
                              id,
                              values: {
                                  like: value,
                              },
                          })
                      },
                  }
                : undefined

        return onboardingButtonProps
    }

    return { getOnboardingButtonProps }
}
