import React from 'react'
import { ChartTable, ProgressBar, SecondaryHorizontalIcon, SingleSelect } from '@diversioteam/diversio-ds'
import { ErrorBoundary } from '@sentry/react'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useDiversityLineChart } from 'hooks/useDiversityLineChart'
import { useExportDiversityDataOverTime } from 'hooks/diversity/export/useExportDiversityDataOverTime'
import { DiversityDataOverTimeParams } from 'api/actions/analyze/diversityData/diversityDataOverTimeActions.types'
import { useTooltips } from 'hooks/tooltips/useTooltips'
import { TooltipId } from 'utils/tooltips.enum'

import { Demographics } from './../diversity.types'
import { DataRepresentationOverTimeProps } from './dataRepresentationOverTime.types'
import * as S from './dataRepresentationOverTime.styles'

const SELECT_LABELS = {
    download: 'Download CSV',
}

const SELECT_OPTIONS = [
    {
        label: SELECT_LABELS.download,
        value: SELECT_LABELS.download,
    },
]

export const DataRepresentationOverTimeWithoutEB = ({
    demographic,
    selectedSurveys,
}: DataRepresentationOverTimeProps) => {
    const { getOnboardingButtonProps } = useTooltips()

    const onboardingButtonProps = getOnboardingButtonProps(TooltipId.DiversityResultsOverTime)

    const demographicName = demographic?.type === Demographics.Bespoke ? demographic.uuid : demographic?.key

    const diversityDataParams: DiversityDataOverTimeParams = {
        years: selectedSurveys,
        value: demographicName || '',
        type: demographic?.type,
    }

    const { data, isLoading } = useDiversityLineChart(diversityDataParams)

    const mutationExportDiversityDataOverTime = useExportDiversityDataOverTime({
        ...diversityDataParams,
        export_format: 'csv',
    })

    const handleSelectDropdownOption = (value: string) => {
        if (value === SELECT_LABELS.download) {
            return mutationExportDiversityDataOverTime.mutate()
        }
    }

    if (isLoading || !data) {
        return null
    }

    const columnsWithBars = data.tableResults.columns.map((tableResult) => {
        if (tableResult.header === 'Group') {
            return tableResult
        }

        tableResult.cell = (info) => {
            const color = info.column.columnDef.meta?.color
            const value = info.getValue<number>()

            if (info.row.depth === 0) {
                return ''
            }

            if (!value || !Number.isFinite(Number(value))) {
                return <ProgressBar color={color} selected={false} unselectedText="No Data" />
            }

            return <ProgressBar color={color} value={value} />
        }

        return tableResult
    })

    return (
        <>
            <S.Header>
                <S.Title>Representation Rates Over Time</S.Title>

                <ErrorBoundary>
                    <SingleSelect
                        color="light"
                        variant="block"
                        listboxId="kpi"
                        isPlaceholderPermanent
                        placeholder=""
                        icon={<SecondaryHorizontalIcon type="line" />}
                        options={SELECT_OPTIONS}
                        value={null}
                        onChange={handleSelectDropdownOption}
                        hideRightIcon
                    />
                </ErrorBoundary>
            </S.Header>

            <ErrorBoundary>
                <ChartTable
                    title={data.group}
                    description="Representation Over Time"
                    data={data.tableResults.data}
                    columns={columnsWithBars}
                    defaultExpanded={{
                        0: true,
                        1: true,
                    }}
                    onboardingButtonProps={onboardingButtonProps}
                />
            </ErrorBoundary>
        </>
    )
}

export const DataRepresentationOverTime = withErrorBoundary(DataRepresentationOverTimeWithoutEB, {})
