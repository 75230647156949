import React, { useEffect, useMemo, useState } from 'react'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import {
    Button,
    ChevronDownIcon,
    DownloadIcon,
    OnboardingButton,
    SearchBox,
    SingleSelect,
} from '@diversioteam/diversio-ds'

import { useGetFreeTextsFilters } from 'hooks/freeTexts/useGetFreeTextsFilters'
import { useTooltips } from 'hooks/tooltips/useTooltips'
import { TooltipId } from 'utils/tooltips.enum'

import { useFreeTextsState } from './hooks/useFreeTextsState'
import { InclusionCategory } from './InclusionCategory'
import { CustomCategory } from './CustomCategory'
import { FreeTextCategory } from './freeTexts.types'
import { ALL_KPIS_NAME, ALL_KPIS_VALUE } from './freeTexts.helpers'
import * as S from './freeTexts.styles'

export const FreeTexts = () => {
    const { searchValue, activeInclusionKpi, onChangeSearchValue, onDownload, onChangeActiveInclusionKpi } =
        useFreeTextsState()
    const queryFreeTextsFilters = useGetFreeTextsFilters()

    const { getOnboardingButtonProps } = useTooltips()
    const onboardingButtonProps = getOnboardingButtonProps(TooltipId.InclusionFreeTexts)

    const isLoading = queryFreeTextsFilters.isLoading

    const availableCategories: FreeTextCategory[] = useMemo(() => {
        const categoriesMap = [
            {
                condition: (queryFreeTextsFilters.data?.inclusionFilterData || []).length > 0,
                category: FreeTextCategory.Inclusion,
            },
            { condition: !!queryFreeTextsFilters.data?.bespokeFreeText?.bespoke, category: FreeTextCategory.Bespoke },
            {
                condition: !!queryFreeTextsFilters.data?.bespokeFreeText?.engagement,
                category: FreeTextCategory.Engagement,
            },
        ]

        return categoriesMap.filter(({ condition }) => condition).map(({ category }) => category)
    }, [queryFreeTextsFilters.data])

    const hasMultipleCategories = availableCategories.length > 1

    const [activeCategoryTab, setActiveCategoryTab] = useState(availableCategories[0])

    useEffect(() => {
        setActiveCategoryTab(availableCategories[0])
    }, [availableCategories])

    const categorySelectOptions = availableCategories.map((category) => ({
        label: category,
        value: category,
    }))

    const getKpiCode = (label: string) =>
        queryFreeTextsFilters.data?.kpiMapping.find(({ name }) => name === label)?.code

    const kpiSelectOptions = [
        { label: ALL_KPIS_NAME, value: ALL_KPIS_VALUE },
        ...(queryFreeTextsFilters.data?.inclusionFilterData || [])
            .map(({ kpiName }) => {
                const kpiCode = getKpiCode(kpiName)

                if (kpiCode === undefined) {
                    return []
                }

                return {
                    label: kpiName,
                    value: kpiCode,
                }
            })
            .flat(),
    ]

    const handleChangeCategoryDropdown = (value: FreeTextCategory) => {
        setActiveCategoryTab(value)
    }

    if (!activeCategoryTab) {
        if (isLoading) {
            return (
                <S.FreeTextsWrapper>
                    <MuiSkeleton variant="rect" width="100%" height={1200} data-testid="free-texts-skeleton" />
                </S.FreeTextsWrapper>
            )
        } else {
            return null
        }
    }

    return (
        <S.FreeTextsWrapper>
            <S.Header>
                <S.Title>Employee Feedback</S.Title>

                <S.Filters>
                    {hasMultipleCategories && (
                        <SingleSelect
                            variant="block-large"
                            icon={<ChevronDownIcon type="line" />}
                            options={categorySelectOptions}
                            value={activeCategoryTab}
                            onChange={handleChangeCategoryDropdown}
                        />
                    )}

                    {activeCategoryTab === FreeTextCategory.Inclusion && (
                        <SingleSelect
                            variant="block-large"
                            icon={<ChevronDownIcon type="line" />}
                            options={kpiSelectOptions}
                            value={activeInclusionKpi}
                            onChange={onChangeActiveInclusionKpi}
                        />
                    )}

                    <SearchBox value={searchValue} onChange={onChangeSearchValue} maxWidth={220} />

                    <Button
                        color="transparent"
                        size="small"
                        rightIcon={<DownloadIcon type="bold" />}
                        onClick={() => onDownload(activeCategoryTab)}
                    >
                        CSV
                    </Button>

                    {onboardingButtonProps && <OnboardingButton placement="bottom-end" {...onboardingButtonProps} />}
                </S.Filters>
            </S.Header>

            {activeCategoryTab === FreeTextCategory.Inclusion && <InclusionCategory />}

            {activeCategoryTab === FreeTextCategory.Bespoke && <CustomCategory category="bespoke" />}

            {activeCategoryTab === FreeTextCategory.Engagement && <CustomCategory category="engagement" />}
        </S.FreeTextsWrapper>
    )
}
