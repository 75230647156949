import React, { useState } from 'react'
import {
    ChartLegendItem,
    ChartTable,
    DiversityAlert,
    OnboardingButtonProps,
    ProgressBar,
    SecondaryHorizontalIcon,
    SingleSelect,
    colors,
} from '@diversioteam/diversio-ds'
import { parseYearQuarter } from 'utils'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import pluralize from 'pluralize'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useDiversityBarChart } from 'hooks/useDiversityBarChart'
import { useExportDiversityData } from 'hooks/diversity/export/useExportDiversityData'
import { DiversityDataParams } from 'api/actions/analyze/diversityData/diversityDataActions.types'
import { TooManyFilters } from 'components/AnalyzeV2/TooManyFilters'
import { Demographics } from '../diversity.types'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { useTooltips } from 'hooks/tooltips/useTooltips'
import { TooltipId } from 'utils/tooltips.enum'
import TAXONOMIES from 'utils/taxonomies'

import { useFilters } from './../../hooks/useFilters'
import { BarChartCard } from './BarChartCard'
import { DataRepresentationProps, RepresentationView, SelectOptions, SelectValue } from './dataRepresentation.types'
import * as S from './dataRepresentation.styles'

const SELECT_LABELS = {
    download: 'Download CSV',
    table: 'View Table',
    chart: 'View Chart',
    chartTable: 'View Chart + Table',
}

const SELECT_OPTIONS: SelectOptions = [
    {
        label: SELECT_LABELS.download,
        value: 'download',
    },
    {
        label: SELECT_LABELS.table,
        value: RepresentationView.Table,
    },
    {
        label: SELECT_LABELS.chart,
        value: RepresentationView.Chart,
    },
    {
        label: SELECT_LABELS.chartTable,
        value: RepresentationView.ChartTable,
    },
]

const DataRepresentationWithoutEB = ({ demographic, selectedFiltersPayload }: DataRepresentationProps) => {
    const { getOnboardingButtonProps } = useTooltips()

    const isDemographicBespoke = demographic?.type === Demographics.Bespoke
    const demographicName = isDemographicBespoke ? demographic.uuid : demographic?.key

    const { setSelectedFilters, selectedFilters, setFiltersOpen } = useFilters()

    const { survey } = useSurveys()

    const diversityDataParams: DiversityDataParams = {
        ...selectedFiltersPayload,
        year: parseYearQuarter(survey),
        value: demographicName || '',
        type: demographic?.type,
    }

    const { data, isLoading } = useDiversityBarChart(diversityDataParams)

    const mutationExportDiversityData = useExportDiversityData({
        ...diversityDataParams,
        export_format: 'csv',
    })

    const { t } = useTranslation()

    const [representationView, setRepresentationView] = useState(RepresentationView.ChartTable)

    const dropdownOptions = SELECT_OPTIONS.filter((option) => option.value !== representationView)

    const alertsMessages =
        data?.alerts?.messages.map((message) => ({
            identity: message.label,
            label: t(`MISC.${message.identity.toUpperCase()}`, message.label),
            description: message.description,
            quarter: data.quarter,
            year: data.year,
        })) || []

    const alertsErrors =
        data?.alerts?.errors.map((message) => ({
            identity: message.label,
            label: t(`MISC.${message.identity.toUpperCase()}`, message.label),
            description: message.description,
            quarter: data.quarter,
            year: data.year,
        })) || []

    const handleSelectDropdownOption = (value: SelectValue) => {
        if (value === 'download') {
            return mutationExportDiversityData.mutate()
        }

        mixpanel.track(TAXONOMIES.ANALYZE_DIVERSITY_VIEW_CHANGE, { view: value })

        return setRepresentationView(value)
    }

    if (isLoading) {
        return null
    }

    if (!data && selectedFilters.length > 0) {
        return (
            <TooManyFilters
                onAdjustFilters={() => setFiltersOpen(true)}
                onClearFilters={() => setSelectedFilters([])}
            />
        )
    }

    if (!data) {
        return null
    }

    const columnsWithBars = data.tableResults.columns.map((tableResult) => {
        if (tableResult.header === 'Group') {
            return tableResult
        }

        tableResult.cell = (info) => {
            const color = info.column.columnDef.meta?.color
            const value = info.getValue<number>()

            if (!value || !Number.isFinite(Number(value))) {
                return <ProgressBar color={color} selected={false} unselectedText="No Data" />
            }

            return <ProgressBar color={color} value={value} />
        }

        return tableResult
    })

    const customGetOnboardingButtonProps = (tooltipId: TooltipId): OnboardingButtonProps | undefined => {
        const onboardingButtonProps = getOnboardingButtonProps(tooltipId)

        if (!onboardingButtonProps?.steps) {
            return undefined
        }

        return {
            ...onboardingButtonProps,
            steps: onboardingButtonProps?.steps.map((step) => ({
                ...step,
                title: step.title?.replaceAll('<group>', pluralize(data.group)),
            })),
        }
    }

    return (
        <>
            <S.Header>
                <header>
                    <S.Title>Representation Rates</S.Title>

                    {data.subtitle && <S.Subtitle>{data.subtitle}</S.Subtitle>}
                </header>

                {[RepresentationView.Table].includes(representationView) && (
                    <S.Legend>
                        <ChartLegendItem
                            label="National Benchmark"
                            marker={{
                                enable: true,
                                color: colors.purple30,
                            }}
                        />

                        <ChartLegendItem
                            label="Industry Average"
                            marker={{
                                enable: true,
                                color: colors.blue30,
                            }}
                        />
                    </S.Legend>
                )}

                <ErrorBoundary>
                    <SingleSelect
                        color="light"
                        variant="block"
                        listboxId="kpi"
                        isPlaceholderPermanent
                        placeholder=""
                        icon={<SecondaryHorizontalIcon type="line" />}
                        options={dropdownOptions}
                        value={null}
                        onChange={handleSelectDropdownOption}
                        hideRightIcon
                    />
                </ErrorBoundary>
            </S.Header>

            <S.RepresentationViews>
                {[RepresentationView.Chart, RepresentationView.ChartTable].includes(representationView) && (
                    <ErrorBoundary>
                        <BarChartCard
                            chartResults={data.chartResults}
                            group={data.group}
                            isLegendVisible={!isDemographicBespoke}
                        />
                    </ErrorBoundary>
                )}

                {[RepresentationView.Table, RepresentationView.ChartTable].includes(representationView) && (
                    <ErrorBoundary>
                        <ChartTable
                            title={data.group}
                            description="Representation"
                            data={data.tableResults.data}
                            columns={columnsWithBars}
                            defaultExpanded={{
                                0: true,
                                1: true,
                            }}
                            helpText={data.helpText}
                            onboardingButtonProps={getOnboardingButtonProps(TooltipId.DiversityChartTable)}
                        />
                    </ErrorBoundary>
                )}
            </S.RepresentationViews>

            <S.Alerts>
                {data?.alerts?.messages.length ? (
                    <ErrorBoundary>
                        <DiversityAlert
                            group={data.group}
                            type="message"
                            alerts={alertsMessages}
                            onboardingButtonProps={customGetOnboardingButtonProps(TooltipId.DiversityAlertMessage)}
                        />
                    </ErrorBoundary>
                ) : null}

                {data?.alerts?.errors.length ? (
                    <ErrorBoundary>
                        <DiversityAlert
                            group={data.group}
                            type="error"
                            alerts={alertsErrors}
                            onboardingButtonProps={customGetOnboardingButtonProps(TooltipId.DiversityAlertError)}
                        />
                    </ErrorBoundary>
                ) : null}
            </S.Alerts>
        </>
    )
}

export const DataRepresentation = withErrorBoundary(DataRepresentationWithoutEB, {})
