import React from 'react'
import { Skeleton } from '@material-ui/lab'

import { ResourceSkeleton } from 'containers/Resources/Resource/skeleton'

import * as S from './../category.styles'

export const CategorySkeleton = () => {
    return (
        <S.CategoryWrapper>
            <S.Header>
                <S.Title>
                    <Skeleton width={120} />
                </S.Title>
            </S.Header>

            <S.List>
                {new Array(4).fill('').map((_, index) => (
                    <ResourceSkeleton key={index} />
                ))}
            </S.List>
        </S.CategoryWrapper>
    )
}
