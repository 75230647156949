import React, { useEffect, useState } from 'react'
import { Button, CloseIcon, DownloadIcon, KpiIcon, SaveIcon, Tooltip } from '@diversioteam/diversio-ds'

import { useResources } from '../hooks/useResources'
import { useSaveTool } from 'hooks/tools/useSaveTool'
import { useDeleteToolFromSaved } from 'hooks/tools/useDeleteToolFromSaved'

import * as S from './sidePanel.styles'

export const SidePanel = () => {
    const [isOpen, setIsOpen] = useState(false)

    const { selectedResource, onChangeSelectedResource } = useResources()

    const mutationSaveTool = useSaveTool()
    const mutationDeleteToolFromSaved = useDeleteToolFromSaved()

    useEffect(() => {
        if (selectedResource) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [selectedResource])

    const handleClose = () => {
        onChangeSelectedResource(null)
    }

    if (!selectedResource) {
        return <S.SidePanelWrapper isOpen={false}></S.SidePanelWrapper>
    }

    const { id, title, description, url, category, painPoints, targetGroupsV2, isSaved, companyToolId } =
        selectedResource

    const kpis = Array.from(new Map(painPoints.map(({ kpi }) => [kpi.code, kpi])).values())

    const handleClickSaveButton = () => {
        if (isSaved && companyToolId) {
            return mutationDeleteToolFromSaved.mutate(companyToolId, {
                onSuccess: () => {
                    onChangeSelectedResource({
                        ...selectedResource,
                        isSaved: false,
                        companyToolId: null,
                    })
                },
            })
        }

        if (!isSaved) {
            return mutationSaveTool.mutate(
                {
                    tool: id,
                },
                {
                    onSuccess: ({ id }) => {
                        onChangeSelectedResource({
                            ...selectedResource,
                            isSaved: true,
                            companyToolId: id,
                        })
                    },
                },
            )
        }
    }

    const renderButton = () => {
        if (category === 'Blog') {
            return (
                <Button component="a" href={url} target="_blank">
                    Read Full Article
                </Button>
            )
        }

        return (
            <Button component="a" href={url} rightIcon={<DownloadIcon type="line" />} target="_blank">
                Download
            </Button>
        )
    }

    return (
        <S.SidePanelWrapper isOpen={isOpen}>
            <Tooltip title="Close">
                <S.CloseButton color="transparent" iconOnly onClick={handleClose}>
                    <CloseIcon type="bold" />
                </S.CloseButton>
            </Tooltip>

            <S.Container>
                <Tooltip title={isSaved ? 'Remove from Favorite' : 'Add to Favorite'}>
                    <S.SaveButton
                        size="small"
                        color={isSaved ? 'primary' : 'transparent'}
                        iconOnly
                        onClick={handleClickSaveButton}
                    >
                        <SaveIcon type="line" />
                    </S.SaveButton>
                </Tooltip>

                <S.Title>{title}</S.Title>

                <S.Description>{description}</S.Description>

                <S.Details>
                    <S.DetailsTitle>Impacted Inclusion Metrics</S.DetailsTitle>

                    <S.DetailsList>
                        {kpis.map(({ name, code }) => (
                            <S.DetailsListItem key={code}>
                                <KpiIcon kpi={code} size="xsmall" /> {name}
                            </S.DetailsListItem>
                        ))}
                    </S.DetailsList>
                </S.Details>

                <S.Details>
                    <S.DetailsTitle>Target Groups Supported</S.DetailsTitle>

                    <S.DetailsList>
                        {targetGroupsV2.map(({ displayName, id }) => (
                            <S.DetailsListItem key={id}>{displayName}</S.DetailsListItem>
                        ))}
                    </S.DetailsList>
                </S.Details>

                <S.Buttons>{renderButton()}</S.Buttons>
            </S.Container>
        </S.SidePanelWrapper>
    )
}
