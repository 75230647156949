import React, { useMemo, useState } from 'react'

import { useDebounce } from 'hooks/useDebounce'
import { Tool, ToolsFilterOption } from 'api/actions/tools/toolsActions.types'

import { ResourcesContext } from './ResourcesContext'
import { ResourcesContextControllerProps } from './ResourcesContextController.types'

export const ResourcesContextController = ({ children }: ResourcesContextControllerProps) => {
    const [searchValue, setSearchValue] = useState('')
    const [selectedResourceTypes, setSelectedResourceTypes] = useState<ToolsFilterOption[]>([])
    const [selectedKpis, setSelectedKpis] = useState<ToolsFilterOption[]>([])
    const [selectedResource, setSelectedResource] = useState<Tool | null>(null)
    const debouncedSearchValue = useDebounce(searchValue, 500)

    const handleChangeSearchValue = (searchValue: string) => {
        setSearchValue(searchValue)
    }

    const handleChangeSelectedResourceTypes = (selectedResourceTypes: ToolsFilterOption[]) => {
        setSelectedResourceTypes(selectedResourceTypes)
    }

    const handleChangeSelectedKpis = (selectedKpis: ToolsFilterOption[]) => {
        setSelectedKpis(selectedKpis)
    }

    const handleChangeSelectedResource = (resource: Tool | null) => {
        setSelectedResource(resource)
    }

    const contextValue = useMemo(
        () => ({
            searchValue,
            debouncedSearchValue: debouncedSearchValue,
            onChangeSearchValue: handleChangeSearchValue,
            selectedResourceTypes,
            onChangeSelectedResourceTypes: handleChangeSelectedResourceTypes,
            selectedKpis,
            onChangeSelectedKpis: handleChangeSelectedKpis,
            selectedResource,
            onChangeSelectedResource: handleChangeSelectedResource,
        }),
        [debouncedSearchValue, searchValue, selectedKpis, selectedResource, selectedResourceTypes],
    )

    return <ResourcesContext.Provider value={contextValue}>{children}</ResourcesContext.Provider>
}
