import React from 'react'

import { ResourcesContextController } from './context/ResourcesContextController'
import { Resources } from './resources'

export const ResourcesContainer = () => {
    return (
        <ResourcesContextController>
            <Resources />
        </ResourcesContextController>
    )
}
