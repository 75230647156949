import React from 'react'
import moment from 'moment'

import { useResources } from '../hooks/useResources'

import { ResourceProps } from './resource.types'
import * as S from './resource.styles'

export const Resource = (props: ResourceProps) => {
    const { id, title, created, category } = props

    const { selectedResource, onChangeSelectedResource } = useResources()

    const date = moment(created).format('MMM D, YYYY')

    const isSelected = selectedResource?.id === id

    const handleClickResource = () => onChangeSelectedResource(props)

    return (
        <S.ResourceWrapper isSelected={isSelected} onClick={handleClickResource}>
            <S.Title>{title}</S.Title>

            <S.Details>
                {category}・{date}
            </S.Details>
        </S.ResourceWrapper>
    )
}
