import styled from 'styled-components'
import { Card as DdsCard } from '@diversioteam/diversio-ds'

import { CardHeader as InclusionCardHeader } from '../../inclusion.styles'

export const Header = styled(DdsCard)`
    position: relative;

    .dds-button {
        margin-left: auto;
    }

    .dds-onboarding-button {
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: block;
            width: 1px;
            height: 18px;
            background-color: ${({ theme }) => theme.colors.primaryGray2};
            margin-right: 8px;
        }
    }
`

export const Overview = styled.div<{ expanded: boolean }>`
    width: 100%;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    gap: 60px 0;
    padding-top: 12px;
    max-height: ${({ expanded }) => (expanded ? '100%' : '68px')};
    overflow: hidden;

    .dds-heat-map-header-cell {
        flex: 1 0 25%;
    }

    .dds-heat-map-header-cell__value {
        line-height: 31px;
    }
`

export const CardHeader = styled(InclusionCardHeader)`
    padding-bottom: 10px;
`
