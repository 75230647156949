import React from 'react'
import { Button, ReloadIcon } from '@diversioteam/diversio-ds'

import { useGetToolsInfinite } from 'hooks/tools/useGetToolsInfinite'
import { ResourceSkeleton } from '../Resource/skeleton'

import { useResources } from './../hooks/useResources'
import { Resource } from './../Resource'
import * as S from './../Category/category.styles'

export const CategoryInfinite = () => {
    const { debouncedSearchValue, selectedKpis, selectedResourceTypes } = useResources()

    const toolsFilterOption = selectedResourceTypes[0]

    const { data, isLoading, fetchNextPage, isFetchingNextPage } = useGetToolsInfinite({
        page_size: 12,
        search: debouncedSearchValue,
        resource_types: [toolsFilterOption.value.toString()],
        kpis: selectedKpis.map(({ value }) => value.toString()),
    })

    const resources = data?.pages

    const hasNextPage = resources && resources[resources.length - 1].next !== null

    const handleClickLoadMore = () => {
        fetchNextPage()
    }

    const renderContent = () => {
        if (resources && resources[0].results.length === 0) {
            return <S.NoResults />
        }

        return (
            <>
                <S.List>
                    {isLoading && new Array(12).fill('').map((_, index) => <ResourceSkeleton key={index} />)}

                    {resources &&
                        resources.flatMap(({ results }) =>
                            results.map((props) => (
                                <Resource
                                    key={props.id}
                                    {...props}
                                    category={toolsFilterOption.label}
                                    isSelected={false}
                                />
                            )),
                        )}
                </S.List>

                {hasNextPage && (
                    <S.LoadMore>
                        <Button
                            rightIcon={<ReloadIcon type="line" />}
                            type="button"
                            color="primaryLight"
                            onClick={handleClickLoadMore}
                            loading={isFetchingNextPage}
                        >
                            Load More
                        </Button>
                    </S.LoadMore>
                )}
            </>
        )
    }

    return (
        <S.CategoryWrapper>
            <S.Header>
                <S.Title>{toolsFilterOption.label}</S.Title>
            </S.Header>

            {renderContent()}
        </S.CategoryWrapper>
    )
}
