import styled from 'styled-components'
import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core'
import { getFontStyle, UnstableChip } from '@diversioteam/diversio-ds'

export const FreeTextsWrapper = styled.section`
    padding: 30px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    box-shadow: 6px 12px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    position: relative;
`

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primaryGray2};

    .dds-input-button {
        white-space: nowrap;
    }
`

export const Category = styled.div<{ isLoading?: boolean }>`
    padding: 0;

    min-height: ${({ isLoading }) => {
        if (isLoading) {
            return '1200px'
        }
    }};
`

export const Title = styled.h2`
    margin: 0;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('cardHeaderBold')};
`

export const Filters = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    gap: 12px;
`

export const Accordion = styled(MuiAccordion)`
    &.MuiAccordion-root {
        border-bottom: 1px solid ${({ theme }) => theme.colors.secondary100Transparent};
        box-shadow: none;

        &.Mui-expanded {
            margin: 0;
        }

        &:before {
            display: none;
        }
    }
`

export const AccordionSummary = styled(MuiAccordionSummary)`
    ${getFontStyle('p02Medium')};

    &.MuiAccordionSummary-root {
        display: flex;
        padding: 24px 0;
        gap: 12px;
        min-height: unset;

        &.Mui-expanded {
            min-height: 64px;
        }
    }

    .MuiAccordionSummary-content {
        display: flex;
        gap: 12px;
        margin: 0;

        &.Mui-expanded {
            margin: 0;
        }
    }

    .MuiAccordionSummary-expandIcon {
        width: 28px;
        height: 28px;
        order: -1;
        margin-right: 0;
        background-color: none;
        border-radius: 10px;

        &:hover {
            background-color: ${({ theme }) => theme.colors.primary20};
        }
    }

    &.Mui-expanded {
        .MuiAccordionSummary-expandIcon {
            background-color: ${({ theme }) => theme.colors.primary20};
        }
    }
`

export const AccordionDetails = styled(MuiAccordionDetails)`
    &.MuiAccordionDetails-root {
        flex-direction: column;
        padding: 0;
    }
`

export const PainPointsList = styled.ul`
    margin: 24px 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`

export const PainPointsListItem = styled.li`
    list-style-type: none;
`

export const Responses = styled.article``

export const Response = styled.section`
    max-width: 824px;
    padding: 0 60px 36px 36px;
`

export const NumberOfResponses = styled.span`
    margin-left: auto;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p02Regular')};
`

export const ResponseText = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.colors.secondary500};

    span:not(.MuiSkeleton-root) {
        background-color: ${({ theme }) => theme.colors.tangerine20};
    }

    ${getFontStyle('p02Regular')};
`

export const ResponseChip = styled(UnstableChip)`
    margin-bottom: 10px;
`

export const LoadMoreButtonWrapper = styled.div`
    padding-top: 10px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;

    ${getFontStyle('captionBold')};
`
