import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { GetToolsFiltersResponse } from 'api/actions/tools/toolsActions.types'
import { getToolsFilters } from 'api/actions/tools/toolsActions'
import { useQuery } from 'hooks/useQuery'

export const useGetToolsFilters = () => {
    const query = useQuery<GetToolsFiltersResponse, AxiosError>({
        queryKey: [Queries.getToolsFilters],
        queryFn: getToolsFilters,
    })

    return query
}
