import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { deleteToolFromSaved } from 'api/actions/tools/toolsActions'
import { Mutations } from 'api/mutations.enum'
import { Queries } from 'api/queries.enum'

export const useDeleteToolFromSaved = () => {
    const queryClient = useQueryClient()

    const mutation = useMutation<void, AxiosError, number>({
        mutationKey: [Mutations.deleteToolFromSaved],
        mutationFn: deleteToolFromSaved,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [Queries.getTools] })
            queryClient.invalidateQueries({ queryKey: [Queries.getToolsInfinite] })
        },
    })

    return mutation
}
