import { AxiosError } from 'axios'

import { useQuery } from 'hooks/useQuery'
import { GetTooltipsResponse } from 'api/actions/tooltips/tooltipActions.types'
import { Queries } from 'api/queries.enum'
import { getTooltips } from 'api/actions/tooltips/tooltipsActions'
import { useGetActiveCompanyInformation } from 'hooks/useGetActiveCompany'
import { useGetDiversityBreakdownByRole } from 'hooks/useGetDiversityBreakdownByRole'

export const useGetTooltips = () => {
    const { companyInformation } = useGetActiveCompanyInformation()
    const { data: dataDiversityBreakdownByRole } = useGetDiversityBreakdownByRole()

    const replacePlaceholders = (text: string) => {
        const replacements: Record<string, string> = {
            '<dashboardName>': companyInformation.name,
            '<diversityBreakdownCategory>': dataDiversityBreakdownByRole?.category || 'Job Levels',
        }

        return Object.keys(replacements).reduce(
            (updatedText, placeholder) => updatedText.replaceAll(placeholder, replacements[placeholder]),
            text,
        )
    }

    const query = useQuery<GetTooltipsResponse, AxiosError>({
        queryKey: [Queries.getTooltips],
        queryFn: getTooltips,
        select: (data) =>
            data.map((dataItem) => ({
                ...dataItem,
                contents: [...dataItem.contents]
                    .sort((a, b) => a.page - b.page)
                    .map((dataItemContent) => ({
                        ...dataItemContent,
                        title: replacePlaceholders(dataItemContent.title),
                        content: replacePlaceholders(dataItemContent.content),
                    })),
            })),
    })

    return query
}
