import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'
import { Drawer as MuiDrawer } from '@material-ui/core'

import { media } from 'styles/theme/mediaQueries'

export const ResourcesWrapper = styled.div`
    display: flex;
    position: relative;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;
    width: 100%;

    ${media.lg} {
        padding: 48px;
    }
`

export const Title = styled.h2`
    margin: 0;
    padding: 0px 8px 0px 12px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h2Bold')};
`

export const Drawer = styled(MuiDrawer)`
    .MuiDrawer-paper {
        height: calc(100% - 86px);
        top: 86px;
        overflow: visible;
        box-shadow: none;
    }
`
