import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { Box } from '@material-ui/core'
import random from 'lodash/random'

import * as S from './filtersSkeleton.styles'

export const FiltersSkeleton = () => {
    return (
        <S.FiltersWrapper>
            <S.Filters>
                <S.FiltersGroup>
                    <Skeleton variant="rect" height={40} width="100%" />
                </S.FiltersGroup>

                <S.FiltersGroup>
                    <S.FiltersGroupTitle>
                        <Skeleton width={100} />
                    </S.FiltersGroupTitle>

                    <S.FormGroup as="ul">
                        {new Array(8).fill('').map((_, index) => (
                            <S.FormGroupItem key={index}>
                                <S.ResourceTypeSkeleton variant="rect" height={30} width={random(40, 120)} />
                            </S.FormGroupItem>
                        ))}
                    </S.FormGroup>
                </S.FiltersGroup>

                <S.FiltersGroup>
                    <S.FiltersGroupTitle>
                        <Skeleton width={100} />
                    </S.FiltersGroupTitle>

                    <S.FormGroup as="ul">
                        {new Array(7).fill('').map((_, index) => (
                            <Box key={index} mb={1} component="li">
                                <Skeleton variant="rect" height={26} width={200} />
                            </Box>
                        ))}
                    </S.FormGroup>
                </S.FiltersGroup>
            </S.Filters>
        </S.FiltersWrapper>
    )
}
