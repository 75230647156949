import React, { useState } from 'react'
import parser from 'html-react-parser'
import { Button, ChevronDownIcon, ReloadAltIcon } from '@diversioteam/diversio-ds'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'

import { useGetBespokeFreeTextsSingleQuestion } from 'hooks/freeTexts/useGetBespokeFreeTextsSingleQuestion'

import { useFreeTextsState } from './../../hooks/useFreeTextsState'
import { FreeTextAccordionProps } from './freeTextAccordion.types'
import * as S from './../../freeTexts.styles'

export const FreeTextAccordion = ({
    text,
    category,
    bespokeQuestion,
    numberOfResponses,
    defaultExpanded = false,
    ...props
}: FreeTextAccordionProps) => {
    const { debouncedSearchValue } = useFreeTextsState()
    const [isExpanded, setIsExpanded] = useState(debouncedSearchValue ? true : defaultExpanded)

    const handleToggleExpanded = () => {
        setIsExpanded((prevState) => !prevState)
    }

    const queryBespokeFreeTextsSingleQuestion = useGetBespokeFreeTextsSingleQuestion(
        {
            search: debouncedSearchValue,
            category,
            bespoke_question: bespokeQuestion,
        },
        {
            enabled: isExpanded,
        },
    )

    const responses =
        queryBespokeFreeTextsSingleQuestion.data?.pages?.flatMap(({ results }) =>
            results?.map(
                ({ text, textSearchResult, translatedText, translatedTextSearchResult }) =>
                    translatedTextSearchResult || textSearchResult || translatedText || text,
            ),
        ) || []

    const isResponsesSkeletonVisible =
        queryBespokeFreeTextsSingleQuestion.isLoading || queryBespokeFreeTextsSingleQuestion.isFetchingNextPage

    const isLoadMoreButtonVisible =
        queryBespokeFreeTextsSingleQuestion.hasNextPage && !queryBespokeFreeTextsSingleQuestion.isFetchingNextPage

    return (
        <S.Accordion expanded={isExpanded} onChange={handleToggleExpanded} {...props}>
            <S.AccordionSummary
                expandIcon={<ChevronDownIcon type="line" />}
                aria-controls={`${text}-content`}
                id={`${text}-header`}
                IconButtonProps={{
                    disableTouchRipple: true,
                }}
            >
                {text}

                <S.NumberOfResponses>{numberOfResponses}</S.NumberOfResponses>
            </S.AccordionSummary>

            <S.AccordionDetails>
                <S.Responses>
                    {responses.map((response, index) => (
                        <S.Response key={index}>
                            <S.ResponseText>{parser(response)}</S.ResponseText>
                        </S.Response>
                    ))}

                    {isResponsesSkeletonVisible && (
                        <S.Response>
                            <MuiSkeleton width="100%" />
                        </S.Response>
                    )}
                </S.Responses>

                {isLoadMoreButtonVisible && (
                    <S.LoadMoreButtonWrapper>
                        <Button
                            size="small"
                            color="transparent"
                            rightIcon={<ReloadAltIcon type="line" />}
                            onClick={() => queryBespokeFreeTextsSingleQuestion.fetchNextPage()}
                        >
                            Load More
                        </Button>
                    </S.LoadMoreButtonWrapper>
                )}
            </S.AccordionDetails>
        </S.Accordion>
    )
}
