import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'
import { FormGroup as MuiFormGroup } from '@material-ui/core'

export const FiltersWrapper = styled.div`
    height: calc(100vh - 86px);
    flex: 0 0 250px;
`

export const Filters = styled.div`
    width: 250px;
    position: fixed;
    top: 86px;
    bottom: 0px;
    left: 70px;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.primaryGray1};
    border-right: 1px solid ${({ theme }) => theme.colors.primaryWhite};
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.08);
    overflow: auto;
`

export const FiltersGroup = styled.div`
    padding: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary100Transparent};

    .MuiIconButton-root {
        padding: 0;
    }

    .MuiRadio-colorSecondary {
        color: ${({ theme }) => theme.colors.primary90};
    }

    .MuiIconButton-colorSecondary:hover {
        background-color: none;
    }

    .MuiFormControlLabel-label {
        padding: 4px 0;
        margin-left: 8px;
        color: ${({ theme }) => theme.colors.secondary500};

        ${getFontStyle('p02Regular')};
    }

    .MuiFormControlLabel-root {
        margin-left: 2px;
        margin-right: 0;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.colors.primary90};
    }

    &:first-child {
        padding-bottom: 8px;
        border-bottom: none;
    }
`

export const FiltersGroupTitle = styled.h4`
    margin-bottom: 18px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p03Semibold')};
`

export const FormGroup = styled(MuiFormGroup)`
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    gap: 8px;
`

export const FormGroupItem = styled.li``
