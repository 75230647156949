import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Mutations } from 'api/mutations.enum'
import { saveTool } from 'api/actions/tools/toolsActions'
import { SaveToolPayload, SaveToolResponse } from 'api/actions/tools/toolsActions.types'
import { Queries } from 'api/queries.enum'

export const useSaveTool = () => {
    const queryClient = useQueryClient()

    const mutation = useMutation<SaveToolResponse, AxiosError, SaveToolPayload>({
        mutationKey: [Mutations.saveTool],
        mutationFn: saveTool,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [Queries.getTools] })
            queryClient.invalidateQueries({ queryKey: [Queries.getToolsInfinite] })
        },
    })

    return mutation
}
