import React from 'react'
import {
    InclusionScoresOverTime,
    OnboardingButton,
    SecondaryHorizontalIcon,
    SingleSelect,
} from '@diversioteam/diversio-ds'
import { ErrorBoundary } from '@sentry/react'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useExportInclusionDataOverTime } from 'hooks/inclusion/export/useExportInclusionDataOverTime'
import { InclusionDataProps, useInclusionScoresOverTime } from 'hooks/useInclusionScoresOverTime'
import { TooltipId } from 'utils/tooltips.enum'
import { useTooltips } from 'hooks/tooltips/useTooltips'

import { useFilters } from './../../hooks/useFilters'
import { DataRepresentationOverTimeProps } from './dataRepresentationOverTime.types'
import * as S from './dataRepresentationOverTime.styles'

const DOWNLOAD_CSV = 'Download CSV'

const SELECT_OPTIONS = [
    {
        label: DOWNLOAD_CSV,
        value: DOWNLOAD_CSV,
    },
]

const DataRepresentationOverTimeWithoutEB = ({ selectedTab }: DataRepresentationOverTimeProps) => {
    const { selectedFilters, selectedSurveysFilter } = useFilters()

    const { getOnboardingButtonProps } = useTooltips()

    const onboardingButtonProps = getOnboardingButtonProps(TooltipId.InclusionResultsOverTime)

    const inclusionDataParams: InclusionDataProps = {
        ...selectedFilters,
        years: selectedSurveysFilter,
        key: selectedTab,
    }

    const { data, isLoading } = useInclusionScoresOverTime({
        ...inclusionDataParams,
    })

    const mutationExportInclusionDataOverTime = useExportInclusionDataOverTime({
        ...inclusionDataParams,
        export_format: 'csv',
    })

    const handleDropdownOptionSelect = (value: string) => {
        switch (value) {
            case DOWNLOAD_CSV:
                return mutationExportInclusionDataOverTime.mutate()
        }
    }

    if (isLoading || !data) {
        return null
    }

    return (
        <S.DataRepresentationOverTimeWrapper>
            <S.Header>
                <S.Title>Inclusion Scores Over Time</S.Title>

                <ErrorBoundary>
                    <SingleSelect
                        color="light"
                        variant="block"
                        listboxId="kpi"
                        isPlaceholderPermanent
                        placeholder=""
                        icon={<SecondaryHorizontalIcon type="line" />}
                        options={SELECT_OPTIONS}
                        value={null}
                        onChange={handleDropdownOptionSelect}
                        hideRightIcon
                    />

                    {onboardingButtonProps && <OnboardingButton placement="bottom-end" {...onboardingButtonProps} />}
                </ErrorBoundary>
            </S.Header>

            <ErrorBoundary>
                <InclusionScoresOverTime data={data} />
            </ErrorBoundary>
        </S.DataRepresentationOverTimeWrapper>
    )
}

export const DataRepresentationOverTime = withErrorBoundary(DataRepresentationOverTimeWithoutEB, {})
