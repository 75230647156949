import React from 'react'
import parser from 'html-react-parser'
import { ChevronDownIcon, Button, ReloadAltIcon } from '@diversioteam/diversio-ds'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'

import { FreeTextAccordionProps } from './freeTextAccordion.types'
import * as S from './../../freeTexts.styles'

export const FreeTextAccordion = ({
    responses,
    text,
    numberOfResponses,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    ...props
}: FreeTextAccordionProps) => {
    const isLoadMoreButtonVisible = hasNextPage && !isFetchingNextPage

    return (
        <S.Accordion {...props}>
            <S.AccordionSummary
                expandIcon={<ChevronDownIcon type="line" />}
                aria-controls={`${text}-content`}
                id={`${text}-header`}
                IconButtonProps={{
                    disableTouchRipple: true,
                }}
            >
                {text}

                <S.NumberOfResponses>{numberOfResponses}</S.NumberOfResponses>
            </S.AccordionSummary>

            <S.AccordionDetails>
                <S.Responses>
                    {responses.map(({ painPoint, text }, index) => {
                        return (
                            <S.Response key={index}>
                                {painPoint && <S.ResponseChip label={painPoint} color="gray" />}

                                <S.ResponseText>{parser(text)}</S.ResponseText>
                            </S.Response>
                        )
                    })}

                    {isFetchingNextPage && (
                        <S.Response>
                            <MuiSkeleton width="100%" />
                        </S.Response>
                    )}

                    {isLoadMoreButtonVisible && (
                        <S.LoadMoreButtonWrapper>
                            <Button
                                size="small"
                                color="transparent"
                                rightIcon={<ReloadAltIcon type="line" />}
                                onClick={fetchNextPage}
                            >
                                Load More
                            </Button>
                        </S.LoadMoreButtonWrapper>
                    )}
                </S.Responses>
            </S.AccordionDetails>
        </S.Accordion>
    )
}
