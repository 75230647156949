import React from 'react'
import { PeopleSurveyedCard, PeopleSurveyedCardSkeleton } from '@diversioteam/diversio-ds'

import { useGetPeopleSurveyedByRole } from 'hooks/useGetPeopleSurveyedByRole'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useTooltips } from 'hooks/tooltips/useTooltips'
import { TooltipId } from 'utils/tooltips.enum'

const PeopleSurveyedWithoutEB = () => {
    const { data, isLoading } = useGetPeopleSurveyedByRole()
    const { getOnboardingButtonProps } = useTooltips()

    const onboardingButtonProps = getOnboardingButtonProps(TooltipId.HomePeopleSurveyed)

    if (isLoading) {
        return <PeopleSurveyedCardSkeleton data-testid="people-surveyed-skeleton" />
    }

    if (!data) {
        return null
    }

    return <PeopleSurveyedCard {...data} onboardingButtonProps={onboardingButtonProps} data-testid="people-surveyed" />
}

export const PeopleSurveyed = withErrorBoundary(PeopleSurveyedWithoutEB, {})
