import React from 'react'
import { SurveyParticipationCard, SurveyParticipationCardSkeleton } from '@diversioteam/diversio-ds'
import isEmpty from 'lodash/isEmpty'

import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { useGetPastSurveys } from 'hooks/useGetPastSurveys'
import { useTooltips } from 'hooks/tooltips/useTooltips'
import { TooltipId } from 'utils/tooltips.enum'

export const SurveyParticipation = () => {
    const { isLoading, data } = useGetPastSurveys()
    const { checkIfSurveyIsSelected, survey } = useSurveys()
    const { getOnboardingButtonProps } = useTooltips()

    if (isLoading || survey === null) {
        return <SurveyParticipationCardSkeleton data-testid="survey-participation-skeleton" />
    }

    if (!data || isEmpty(data)) {
        return null
    }

    const selectedSurveyData = data.pastSurveys.filter((survey) => checkIfSurveyIsSelected(survey))[0]

    if (!selectedSurveyData) {
        return null
    }

    const { participationRate, numberOfResponses, numberOfEmployees } = selectedSurveyData

    const onboardingButtonProps = getOnboardingButtonProps(TooltipId.HomeSurveyParticipation)

    return (
        <SurveyParticipationCard
            participationRate={participationRate}
            numberOfResponses={numberOfResponses}
            numberOfEmployees={numberOfEmployees}
            onboardingButtonProps={onboardingButtonProps}
            data-testid="survey-participation"
        />
    )
}
