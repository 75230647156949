import styled from 'styled-components'
import { Button as DdsButton, getFontStyle } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const SidePanelWrapper = styled.div<{ isOpen: boolean }>`
    width: 544px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    box-shadow: 6px 12px 60px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 86px;
    bottom: 0;
    right: 0;
    transform: ${({ isOpen }) => {
        if (isOpen) {
            return 'translateX(0)'
        }

        return 'translateX(100%)'
    }};
    transition: all 0.2s ease-in-out;

    .dds-button {
        overflow: hidden;
    }
`

export const Container = styled.div`
    height: calc(100vh - 88px);
    overflow: auto;
    padding: 48px;
    display: flex;
    flex-direction: column;

    ${media.lg} {
        padding: 48px;
    }
`

export const CloseButton = styled(DdsButton)`
    &.dds-button {
        position: absolute;
        left: -48px;
        top: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.primaryWhite};
        box-shadow: 6px 12px 60px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
    }
`

export const SaveButton = styled(DdsButton)`
    &.dds-button {
        margin-left: auto;
        margin-bottom: 24px;
    }
`

export const Title = styled.h2`
    margin: 0 0 12px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p01Semibold')}
`

export const Description = styled.p`
    margin: 0 0 24px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('subheaderRegular')}
`

export const Details = styled.div`
    margin-top: 80px;

    &:last-of-type {
        margin-bottom: 24px;
    }
`

export const DetailsTitle = styled.h3`
    margin-bottom: 18px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p02Medium')}
`

export const DetailsList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px 24px;
`

export const DetailsListItem = styled.li`
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p02Regular')}
`

export const Buttons = styled.div`
    flex: 1;
    display: flex;
    margin-top: 24px;
    align-items: flex-end;
    gap: 12px;
    padding-top: 0px;

    .dds-button {
        width: 100%;
    }
`
