import { useContext } from 'react'

import { ResourcesContext } from './../context/ResourcesContext'

export const useResources = () => {
    const context = useContext(ResourcesContext)

    if (context === undefined) {
        throw new Error('ResourcesContext must be within ResourcesContextController')
    }

    return context
}
