import { Skeleton } from '@material-ui/lab'
import styled from 'styled-components'

export const SearchBoxSkeleton = styled(Skeleton)`
    border-radius: 10px;
`

export const ResourceTypeSkeleton = styled(Skeleton)`
    border-radius: 8px;
`

export * from './../filters.styles'
