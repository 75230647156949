import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getBespokeFreeTexts } from 'sagas/network'
import { stringifyQueryParams } from 'utils'

import {
    GetBespokeFreeTextsParams,
    GetBespokeFreeTextsResponse,
} from 'api/actions/analyze/freeTexts/freeTextsActions.types'
import { Queries } from 'api/queries.enum'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

export const useGetBespokeFreeTexts = (defaultParams?: GetBespokeFreeTextsParams) => {
    const queryClient = useQueryClient()
    const { survey } = useSurveys()

    const selectedSurveyParams = survey
        ? {
              year: survey.year,
              quarter: survey.quarter,
          }
        : {}

    const params: GetBespokeFreeTextsParams = { ...selectedSurveyParams, ...defaultParams }

    const query = useQuery<GetBespokeFreeTextsResponse, Error>({
        queryKey: [Queries.getBespokeFreeTexts, params],
        queryFn: () => getBespokeFreeTexts(stringifyQueryParams(params)),
        ...preventRefetchQueryOptions,
        onSuccess: (data) => {
            data.forEach((dataItem) => {
                queryClient.setQueryData(
                    [Queries.getBespokeFreeTexts, { ...params, bespoke_question: dataItem.uuid }],
                    { pages: [dataItem], pageParams: [null] },
                )
            })
        },
    })

    return query
}
