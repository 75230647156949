import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const ResourceWrapper = styled.div<{ isSelected: boolean }>`
    height: 100%;
    padding: 24px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    cursor: pointer;
    padding: 24px;

    outline: ${({ isSelected, theme }) => {
        if (isSelected) {
            return `4px solid ${theme.colors.primary40}`
        }
    }};

    ${media.lg} {
        padding: 32px;
    }
`

export const Title = styled.h3`
    margin: 0 0 24px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p01Semibold')}
`

export const Details = styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p02Regular')}
`
