import styled from 'styled-components'

export const Legend = styled.div`
    display: flex;
    align-items: center;
`

export const RepresentationViews = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`

export const Alerts = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .MuiAccordion-root:before {
        display: none;
    }
`

export { Header, Title, Subtitle } from './../diversity.styles'
