import { AxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'

import { downloadJobAdvertAction } from 'api/actions/lumi/lumiActions'
import { Mutations } from 'api/actions/lumi/mutations.enum'
import { DownloadJobAdvertPayload } from 'api/actions/lumi/lumiActions.types'

export const useDownloadJobAdvert = () => {
    const mutation = useMutation<void, AxiosError, DownloadJobAdvertPayload>({
        mutationKey: [Mutations.downloadJobAdvert],
        mutationFn: downloadJobAdvertAction,
    })

    return mutation
}
