export enum TooltipId {
    SurveySelectTopNavbar = 'survey-select-top-navbar',
    HomeDiversityBreakdown = 'home-diversity-breakdown',
    HomeDiversityBreakdownByRole = 'home-diversity-breakdown-by-role',
    HomeInclusionHeatmap = 'home-inclusion-heatmap',
    HomeInclusionInsights = 'home-inclusion-insights',
    HomeInclusionScore = 'home-inclusion-score',
    HomeNps = 'home-nps',
    HomePeopleSurveyed = 'home-people-surveyed',
    HomeSurveyParticipation = 'home-survey-participation',
    DiversityChart = 'diversity-chart',
    DiversityChartTable = 'diversity-chart-table',
    DiversityResultsOverTime = 'diversity-results-over-time',
    DiversityProfiles = 'diversity-profiles',
    DiversityAlertMessage = 'diversity-alert-message',
    DiversityAlertError = 'diversity-alert-error',
    InclusionHeatmapHeader = 'inclusion-heatmap-header',
    InclusionHeatmap = 'inclusion-heatmap',
    InclusionResultsOverTime = 'inclusion-results-over-time',
    InclusionProfiles = 'inclusion-profiles',
    InclusionFreeTexts = 'inclusion-free-texts',
}
