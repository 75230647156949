import { SystemButton as DdsSystemButton, Card as DdsCard, colors } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

import { CardTitle as Title, CardHeader as Header } from '../../inclusion.styles'

export const HeatmapTables = styled.table<{ isPanelVisible: boolean }>`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-right: ${({ isPanelVisible }) => (isPanelVisible ? '336px' : '16px')};
    padding-bottom: 8px;
    padding-right: 16px;

    .react-resizable-handle {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50px;
        z-index: 9999999;
        cursor: col-resize;
        display: none;
        margin-right: -25px;
    }

    .react-resizable {
        position: sticky;
        left: 0;
        z-index: 9;
        border-right: 1px inset ${({ theme }) => theme.colors.primaryGray2};

        &:hover {
            border-right: 2px inset ${({ theme }) => theme.colors.primaryGray2};
            .react-resizable-handle {
                display: block;
            }
        }

        &.resizing {
            border-right: 4px solid ${({ theme }) => theme.colors.primaryGray2};
        }
    }
`

export const Card = styled(DdsCard)<{ overflow: boolean }>`
    width: ${({ overflow }) => (overflow ? 'calc(100% + 70px)' : '100%')};
    overflow: hidden;
    position: relative;

    .dds-onboarding-button {
        position: absolute;
        top: 12px;
        right: 12px;
    }
`

export const ScrollBar = styled.div`
    overflow-x: scroll;
    width: 100%;
`

export const CardHeader = styled(Header)`
    flex-direction: column;
    align-items: start;
    padding-bottom: 20px;
`

export const CardTitle = styled(Title)`
    display: flex;
    align-items: center;
`

export const DownloadButton = styled(DdsSystemButton)`
    margin-left: 6px;

    &.dds-system-button div[class*='-icon'] {
        background-color: ${({ theme }) => theme.colors.primary90};
    }
`

export const Legends = styled.div`
    display: flex;
    gap: 24px;
    margin-top: 28px;
    width: 100%;

    .bold.point-icon {
        mask-image: none;
        border-radius: 6px;
        margin-right: 6px;
    }

    .dds-chart-legend-item {
        max-width: fit-content;

        &:not(:last-of-type) {
            border-bottom: none;
        }
    }

    .dds-chart-legend-item__label {
        color: ${({ theme }) => theme.colors.secondary500};
    }
`

export const DashIcon = styled.span`
    font-size: 32px;
    height: 28px;
    margin-bottom: 18px;
`

export const AsteriskIcon = styled.span`
    font-size: 32px;
    height: 28px;
    color: ${colors.primary90};
`
