import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { GetToolsParams, GetToolsResponse } from 'api/actions/tools/toolsActions.types'
import { getTools } from 'api/actions/tools/toolsActions'
import { useQuery } from 'hooks/useQuery'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

export const useGetTools = (params?: GetToolsParams) => {
    const query = useQuery<GetToolsResponse, AxiosError>({
        queryKey: [Queries.getTools, params],
        queryFn: () => getTools(params),
        ...preventRefetchQueryOptions,
    })

    return query
}
