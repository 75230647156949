import React from 'react'
import { Button, EditIcon, SingleSelect } from '@diversioteam/diversio-ds'
import mixpanel from 'mixpanel-browser'

import { useDownloadJobAdvert } from 'hooks/lumi/useDownloadJobAdvert'
import { DownloadJobAdvertFileType } from 'api/actions/lumi/lumiActions.types'
import TAXONOMIES from 'utils/taxonomies'

import { useLumi } from './../hooks/useLumi'
import * as S from './completedCard.styles'

const SELECT_OPTIONS: { label: string; value: DownloadJobAdvertFileType }[] = [
    {
        label: '.docx',
        value: 'docx',
    },
    {
        label: '.pdf',
        value: 'pdf',
    },
    {
        label: '.txt',
        value: 'txt',
    },
]

export const CompletedCard = () => {
    const { jobId, onResetStage } = useLumi()

    const { mutate } = useDownloadJobAdvert()

    const handleDropdownOptionSelect = (value: DownloadJobAdvertFileType) => {
        mixpanel.track(TAXONOMIES.LUMI_DOWNLOAD_JOB_AD)

        mutate({
            jobId,
            params: {
                file_type: value,
            },
        })
    }

    const onClickNewProject = () => {
        mixpanel.track(TAXONOMIES.LUMI_NEW_PROJECT)

        onResetStage()
    }

    return (
        <S.CompletedCard>
            <S.Title>Your job posts are ready</S.Title>

            <S.Buttons>
                <SingleSelect
                    color="dark"
                    variant="block"
                    placeholder="Download"
                    options={SELECT_OPTIONS}
                    value={null}
                    onChange={handleDropdownOptionSelect}
                />

                <Button color="secondary" onClick={onClickNewProject} rightIcon={<EditIcon type="line" />}>
                    New Project
                </Button>
            </S.Buttons>
        </S.CompletedCard>
    )
}
