import { stringifyQueryParams } from 'utils'

import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import {
    GetToolsFiltersResponse,
    GetToolsParams,
    GetToolsResponse,
    SaveToolPayload,
    SaveToolResponse,
} from './toolsActions.types'

export const getToolsFilters = async (): Promise<GetToolsFiltersResponse> => {
    const response = await diversioApiV2.get(endpoints.tools.filters)

    return response.data
}

export const getTools = async (params?: GetToolsParams): Promise<GetToolsResponse> => {
    const response = await diversioApiV2.get(endpoints.tools.list, {
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    return response.data
}

export const saveTool = async (body: SaveToolPayload): Promise<SaveToolResponse> => {
    const response = await diversioApiV2.post(endpoints.tools.saveTool, body)

    return response.data
}

export const deleteToolFromSaved = async (id: number): Promise<void> => {
    await diversioApiV2.delete(endpoints.tools.deleteTool(id))
}
