import React from 'react'
import { Skeleton } from '@material-ui/lab'

import * as S from './../resource.styles'

export const ResourceSkeleton = () => {
    return (
        <S.ResourceWrapper isSelected={false}>
            <S.Title>
                <Skeleton width={120} />
            </S.Title>

            <S.Details>
                <Skeleton width={80} />・<Skeleton width={80} />
            </S.Details>
        </S.ResourceWrapper>
    )
}
