import React, { ChangeEvent, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { withTranslation } from 'react-i18next'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import { OnboardingButton, SingleSelect, TopbarMenu } from '@diversioteam/diversio-ds'
import { TopbarMenuActions } from '@diversioteam/diversio-ds/dist/components/core/TopbarMenu/topbarMenu.types'
import i18next from 'i18next'
import mixpanel from 'mixpanel-browser'

import { useBenchmarks } from 'hooks/benchmarks/useBenchmarks'
import { useSetPrimaryBenchmark } from 'hooks/benchmarks/useSetPrimaryBenchmark'
import { useVersion } from 'hooks/useVersion'
import { LocationBasedContent } from 'hoc/LocationBasedContent/LocationBasedContent'
import { RootState } from 'reducers/rootState.types'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { useGetCompanyFilters } from 'hooks/companyFilters/useGetCompanyFilters'
import { AppRoute } from 'routing/AppRoute.enum'
import { useIsAllowedPath } from 'hooks/useIsAllowedPath'
import { useTooltips } from 'hooks/tooltips/useTooltips'
import { TooltipId } from 'utils/tooltips.enum'

import { Actions } from './../../actions'
import { BenchmarkSwitch } from './BenchmarkSwitch'
import { TopNavBarProps } from './topNavBar.types'
import TAXONOMIES from './../../utils/taxonomies'
import * as S from './topNavBar.styles'

const TopNavBar = ({
    selectedLanguage,
    showLanguages,
    companyList,
    activeDashboard,
    onChangeCompany,
    onChangeLanguage,
    onShowPastSurveys,
}: TopNavBarProps) => {
    const { username } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch()

    const versionsContext = useVersion()

    const queryClient = useQueryClient()
    const isV2Enabled = versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v2

    const { isAllowedPath } = useIsAllowedPath()

    const benchmarks = useBenchmarks()
    const mutationSetPrimaryNationalBenchmark = useSetPrimaryBenchmark('national')
    const mutationSetPrimaryIndustryBenchmark = useSetPrimaryBenchmark('industry')

    const { survey, onChangeSurvey } = useSurveys()
    const queryGetCompanyFilters = useGetCompanyFilters()
    const { getOnboardingButtonProps } = useTooltips()

    const surveySelectOptions =
        queryGetCompanyFilters.data?.surveys.map(({ name }) => ({
            label: name,
            value: name,
        })) || []

    const surveySelectOnboardingButtonProps = getOnboardingButtonProps(TooltipId.SurveySelectTopNavbar)

    const handleChangeSurveySelect = (value: string) => {
        const survey = queryGetCompanyFilters.data?.surveys.find(({ name }) => name === value)

        if (survey) {
            onChangeSurvey(survey)
        }
    }

    const benchmarkProps = isV2Enabled
        ? {
              nationalBenchmarks: benchmarks.queryNationalBenchmarks?.data || [],
              industryBenchmarks: benchmarks.queryIndustryBenchmarks?.data || [],
              activeNationalBenchmark: benchmarks.primaryNationalBenchmark?.value || '',
              activeIndustryBenchmark: benchmarks.primaryIndustryBenchmark?.value || '',
          }
        : {}

    useEffect(() => {
        queryClient.invalidateQueries()
    }, [benchmarkProps.activeNationalBenchmark, benchmarkProps.activeIndustryBenchmark])

    const allowedPaths = {
        analyze: [AppRoute.AnalyzeDiversity, AppRoute.AnalyzeInclusion],
        industryBenchmark: [AppRoute.Home, AppRoute.AnalyzeDiversity, AppRoute.AnalyzeInclusion],
        nationalBenchmark: [AppRoute.Home, AppRoute.AnalyzeDiversity],
    }

    const allowedPathsArr = ([] as string[]).concat(...Object.values(allowedPaths))

    const handleChangeLanguage = (e: ChangeEvent<{ value: unknown }>) => {
        const value = e.target.value as string

        i18next.changeLanguage(value)

        onChangeLanguage(value)
    }

    const handleChangeCompany = (company: string) => {
        queryClient.cancelQueries()
        queryClient.clear()

        onChangeCompany(company)
    }

    const handleLogout = () => {
        mixpanel.track(TAXONOMIES.DASHBOARD_SESSION_ENDED, {
            dashboardName: activeDashboard.label,
            user: username,
            action: 'Sign out',
        })

        dispatch(
            Actions.doSignout({
                clearSession: true,
            }),
        )
    }

    const menuItems: TopbarMenuActions[] = ['surveyDates', 'switchDashboards', 'settings', 'logout']

    if (versionsContext?.isVersionSwitchable) {
        const versionOption =
            versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v1 ? 'newDesign' : 'classicDesign'

        if (!menuItems.includes(versionOption)) {
            menuItems.splice(2, 0, versionOption)
        }
    }

    return (
        <S.Container hasCustomSpacing={isAllowedPath(allowedPaths.analyze)}>
            {isV2Enabled && (
                <>
                    <LocationBasedContent allowedPaths={allowedPathsArr}>
                        <S.SurveySelectBox>
                            <SingleSelect
                                variant="block-large"
                                a11y={{ 'aria-label': 'Select a survey' }}
                                placeholder="Select a survey"
                                value={survey?.name}
                                options={surveySelectOptions}
                                minWidth={202}
                                onChange={handleChangeSurveySelect}
                            />

                            {surveySelectOnboardingButtonProps && (
                                <OnboardingButton {...surveySelectOnboardingButtonProps} placement="bottom-start" />
                            )}
                        </S.SurveySelectBox>
                    </LocationBasedContent>
                </>
            )}

            {showLanguages ? (
                <FormControl>
                    <Select value={selectedLanguage} onChange={handleChangeLanguage}>
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="de">German</MenuItem>
                    </Select>
                </FormControl>
            ) : null}

            {isV2Enabled && (
                <LocationBasedContent allowedPaths={allowedPathsArr}>
                    <S.Switch data-joyride="home-benchmark-switch">
                        <LocationBasedContent allowedPaths={allowedPaths.industryBenchmark}>
                            {benchmarkProps?.industryBenchmarks?.length ? (
                                <BenchmarkSwitch
                                    label="Industry"
                                    tooltipText=""
                                    benchmarks={benchmarkProps.industryBenchmarks}
                                    activeBenchmark={benchmarkProps.activeIndustryBenchmark}
                                    onChange={mutationSetPrimaryIndustryBenchmark.mutate}
                                />
                            ) : null}
                        </LocationBasedContent>

                        <LocationBasedContent allowedPaths={allowedPaths.nationalBenchmark}>
                            {benchmarkProps?.nationalBenchmarks?.length ? (
                                <BenchmarkSwitch
                                    label="National"
                                    tooltipText=""
                                    benchmarks={benchmarkProps.nationalBenchmarks}
                                    activeBenchmark={benchmarkProps.activeNationalBenchmark}
                                    onChange={mutationSetPrimaryNationalBenchmark.mutate}
                                />
                            ) : null}
                        </LocationBasedContent>
                    </S.Switch>
                </LocationBasedContent>
            )}

            <TopbarMenu
                companies={companyList}
                menuItemsProps={{
                    logout: {
                        onClick: handleLogout,
                    },
                    settings: {
                        component: RouterLink,
                        to: '/account/profile',
                    },
                    surveyDates: {
                        onClick: onShowPastSurveys,
                        to: '/',
                    },
                    newDesign: {
                        onClick: () => versionsContext?.setShowModal(true),
                        to: '#',
                    },
                    classicDesign: {
                        onClick: () => versionsContext?.setShowModal(true),
                        to: '#',
                    },
                }}
                menuItems={menuItems}
                activeCompany={activeDashboard.value}
                onChangeCompany={handleChangeCompany}
            />
        </S.Container>
    )
}

export default withTranslation()(TopNavBar)
