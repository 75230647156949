import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetTooltipsResponse, VoteTooltipPayload, VoteTooltipResponse } from './tooltipActions.types'

export const getTooltips = async (): Promise<GetTooltipsResponse> => {
    const response = await diversioApiV2.get(endpoints.tooltips.list)

    return response.data
}

export const voteTooltip = async ({ id, values }: VoteTooltipPayload): Promise<VoteTooltipResponse> => {
    const response = await diversioApiV2.post(endpoints.tooltips.vote(id), values)

    return response.data
}
