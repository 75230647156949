import React from 'react'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import { AlertIcon, NoResults, UnstableChip as Chip } from '@diversioteam/diversio-ds'

import { useGetFreeTexts } from 'hooks/freeTexts/useGetFreeTexts'
import { useGetFreeTextsFilters } from 'hooks/freeTexts/useGetFreeTextsFilters'
import { PainPoint } from 'api/actions/analyze/freeTexts/freeTextsActions.types'

import { useFreeTextsState } from './../hooks/useFreeTextsState'
import { FreeTextAccordionSkeleton } from './FreeTextAccordionSkeleton'
import { FreeTextAccordion } from './FreeTextAccordion'
import * as S from './../freeTexts.styles'

export const InclusionCategory = () => {
    const { activeInclusionKpi, debouncedSearchValue, selectedInclusionPainPoint, onChangeSelectedInclusionPainPoint } =
        useFreeTextsState()

    const isAllKpisSelected = activeInclusionKpi === 'ALL'

    const queryFreeTextsFilters = useGetFreeTextsFilters()

    const queryFreeTexts = useGetFreeTexts({
        search: debouncedSearchValue,
        pain_point: selectedInclusionPainPoint || undefined,
        ...(!isAllKpisSelected && { kpi: activeInclusionKpi }),
    })

    const getPainPointValue = (painPointName: string): string =>
        queryFreeTextsFilters.data?.painPointMapping?.find(({ title }) => title === painPointName)?.code || ''

    const activeInclusionKpiName = queryFreeTextsFilters.data?.kpiMapping.find(
        ({ code }) => code === activeInclusionKpi,
    )?.name

    const formatPainPoints = (painPoints: PainPoint[]) =>
        painPoints.map(({ painPointTitle }) => ({
            label: painPointTitle,
            value: getPainPointValue(painPointTitle),
        }))

    const allPainPoints = [
        { label: 'All', value: null },
        ...(queryFreeTextsFilters.data?.inclusionFilterData || []).flatMap(({ painPoints }) =>
            formatPainPoints(painPoints),
        ),
    ]

    const kpiBasedPainPoints = [
        { label: 'All', value: null },
        ...formatPainPoints(
            queryFreeTextsFilters.data?.inclusionFilterData?.find(
                (dataItem) => dataItem.kpiName === activeInclusionKpiName,
            )?.painPoints || [],
        ),
    ]

    const painPoints = isAllKpisSelected ? allPainPoints : kpiBasedPainPoints

    const painPointsSkeleton = new Array(24).fill('').map((_, index) => (
        <S.PainPointsListItem key={index}>
            <Chip label={<MuiSkeleton width={120} />} color="white" />
        </S.PainPointsListItem>
    ))

    const numberOfResponses = queryFreeTexts.data?.pages[0].count || 0

    const accordionText = queryFreeTexts.data?.pages[0].question || ''

    const responses =
        queryFreeTexts.data?.pages?.flatMap(({ results }) =>
            results?.map(({ painPoint, text, textSearchResult, translatedText, translatedTextSearchResult }) => ({
                painPoint: painPoint.title,
                text: translatedTextSearchResult || textSearchResult || translatedText || text,
            })),
        ) || []

    const responseSkeletonComponent = <FreeTextAccordionSkeleton />

    const responsesComponent =
        responses.length === 0 ? (
            <NoResults />
        ) : (
            <FreeTextAccordion
                defaultExpanded
                text={accordionText}
                responses={responses}
                numberOfResponses={numberOfResponses}
                hasNextPage={!!queryFreeTexts.hasNextPage}
                fetchNextPage={queryFreeTexts.fetchNextPage}
                isFetchingNextPage={queryFreeTexts.isFetchingNextPage}
            />
        )

    return (
        <S.Category isLoading={queryFreeTexts.isLoading} data-testid="free-texts-inclusion">
            <S.PainPointsList>
                {painPoints.map(({ label, value }) => (
                    <S.PainPointsListItem key={label}>
                        {value === 'HARASSMENT' ? (
                            <Chip
                                label={label}
                                color="danger"
                                size="medium"
                                leftIcon={<AlertIcon type="bold" />}
                                selected={selectedInclusionPainPoint === value}
                                onClick={() => onChangeSelectedInclusionPainPoint(value)}
                            />
                        ) : (
                            <Chip
                                label={label}
                                color="gray"
                                size="medium"
                                selected={selectedInclusionPainPoint === value}
                                onClick={() => onChangeSelectedInclusionPainPoint(value)}
                            />
                        )}
                    </S.PainPointsListItem>
                ))}

                {queryFreeTextsFilters.isLoading && painPointsSkeleton}
            </S.PainPointsList>

            {queryFreeTexts.isLoading ? responseSkeletonComponent : responsesComponent}
        </S.Category>
    )
}
