import React, { useEffect, useRef, useState } from 'react'
import {
    HeatMapHeaderCell,
    InfoIcon,
    Tooltip,
    ChevronDownIcon,
    ChevronUpIcon,
    Button,
    UnstableChip as Chip,
    HeatMapHeaderCellSkeleton,
    OnboardingButton,
} from '@diversioteam/diversio-ds'
import { ErrorBoundary } from '@sentry/react'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useInclusionData } from 'hooks/useInclusionData'
import { useGetInclusionScoresSkeleton } from 'hooks/inclusion/useGetInclusionScoresSkeleton'
import { useTooltips } from 'hooks/tooltips/useTooltips'
import { TooltipId } from 'utils/tooltips.enum'

import { CardTitle } from './../../inclusion.styles'
import { isDataUnavailable } from './../heatmap.utils'
import { HeatmapHeaderProps, TooltipProps } from './header.types'
import { getCompareBadgeCells } from './header.utils'
import * as S from './header.styles'

const renderTooltip = (data: TooltipProps) => {
    if (isDataUnavailable(data.tooltip)) {
        return undefined
    }

    return (
        <Tooltip title={data.tooltip} size="medium" variant="light">
            <div>
                <InfoIcon type="line" width={10} height={10} color="#aeabb8" />
            </div>
        </Tooltip>
    )
}

const HeatmapHeaderWithoutEB = ({
    inclusionDataParams,
    hasInclusionChip,
    inclusionScoresSkeletonParams,
}: HeatmapHeaderProps) => {
    const content = useRef<HTMLDivElement>(null)

    const [headerExpanded, setHeaderExpanded] = useState<boolean>(false)
    const [enableShowMore, setEnableShowMore] = useState(false)

    const queryInclusionData = useInclusionData(inclusionDataParams)
    const queryGetInclusionScoresSkeleton = useGetInclusionScoresSkeleton(inclusionScoresSkeletonParams)

    const { getOnboardingButtonProps } = useTooltips()
    const onboardingButtonProps = getOnboardingButtonProps(TooltipId.InclusionHeatmapHeader)

    useEffect(() => {
        const handleResize = () => {
            const scrollHeight = content.current?.scrollHeight || 0
            const offsetHeight = content.current?.offsetHeight || 0

            setEnableShowMore(scrollHeight > offsetHeight)
        }

        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [content, inclusionDataParams.key])

    const isInclusionScoresSkeletonVisible = queryInclusionData.isLoading && queryGetInclusionScoresSkeleton.data

    const dataWithScores = queryInclusionData.data?.overall.filter(({ score }) => !isDataUnavailable(score)) || []

    return (
        <S.Header>
            <S.CardHeader>
                <CardTitle>
                    Employee Experience {hasInclusionChip && <Chip label="Inclusion Scores" color="gray" />}
                </CardTitle>

                {enableShowMore && (
                    <Button
                        color="transparent"
                        leftIcon={
                            headerExpanded ? (
                                <ChevronUpIcon type="line" width={16} height={16} />
                            ) : (
                                <ChevronDownIcon type="line" width={16} height={16} />
                            )
                        }
                        onClick={() => setHeaderExpanded(!headerExpanded)}
                    >
                        Show {headerExpanded ? 'Less' : 'All'}
                    </Button>
                )}

                {onboardingButtonProps && <OnboardingButton placement="bottom-end" {...onboardingButtonProps} />}
            </S.CardHeader>

            <S.Overview expanded={headerExpanded} ref={content}>
                {isInclusionScoresSkeletonVisible &&
                    queryGetInclusionScoresSkeleton.data.overall.map(({ title }) => (
                        <HeatMapHeaderCellSkeleton key={title} name={title} size="small" />
                    ))}

                {dataWithScores.map((overallData, idx) => {
                    if (isDataUnavailable(overallData.score)) {
                        return null
                    }

                    return (
                        <ErrorBoundary key={`heatmap-header-overall-${idx}`}>
                            <HeatMapHeaderCell
                                name={overallData.title}
                                value={overallData.score}
                                size="small"
                                nameChildren={renderTooltip(overallData)}
                                compareBadgeCells={getCompareBadgeCells(overallData)}
                            />
                        </ErrorBoundary>
                    )
                })}
            </S.Overview>
        </S.Header>
    )
}

export const HeatmapHeader = withErrorBoundary(HeatmapHeaderWithoutEB, {})
