import React, { useState } from 'react'
import { ArrowLeftIcon, ArrowRightIcon, Button, colors } from '@diversioteam/diversio-ds'

import { useGetTools } from 'hooks/tools/useGetTools'
import { useResources } from '../hooks/useResources'
import { ResourceSkeleton } from '../Resource/skeleton'

import { Resource } from './../Resource'
import { CategoryProps } from './category.types'
import * as S from './category.styles'

export const Category = ({ toolsFilterOption }: CategoryProps) => {
    const [page, setPage] = useState(1)

    const { debouncedSearchValue, selectedKpis, onChangeSelectedResourceTypes } = useResources()

    const { data, isLoading } = useGetTools({
        page,
        page_size: 4,
        search: debouncedSearchValue,
        resource_types: [toolsFilterOption.value.toString()],
        kpis: selectedKpis.map(({ value }) => value.toString()),
    })

    const results = data?.results

    const hasPreviousPage = data?.previous !== null
    const hasNextPage = data?.next !== null

    const handleClickPreviousPage = () => {
        setPage((prevState) => prevState - 1)
    }

    const handleClickNextPage = () => {
        setPage((prevState) => prevState + 1)
    }

    const handleChangedSelectedResourceTypes = () => {
        onChangeSelectedResourceTypes([toolsFilterOption])
    }

    if (results && results.length === 0) {
        return null
    }

    return (
        <S.CategoryWrapper>
            <S.Header>
                <S.Title onClick={handleChangedSelectedResourceTypes}>
                    {toolsFilterOption.label}{' '}
                    <ArrowRightIcon type="bold" color={colors.primary50} width={16} height={16} />
                </S.Title>

                <S.Navigation>
                    <Button
                        onClick={handleClickPreviousPage}
                        disabled={!hasPreviousPage}
                        size="small"
                        color="primaryLight"
                        iconOnly
                    >
                        <ArrowLeftIcon type="bold" />
                    </Button>

                    <Button
                        onClick={handleClickNextPage}
                        disabled={!hasNextPage}
                        size="small"
                        color="primaryLight"
                        iconOnly
                    >
                        <ArrowRightIcon type="bold" />
                    </Button>
                </S.Navigation>
            </S.Header>

            <S.List>
                {isLoading && new Array(4).fill('').map((_, index) => <ResourceSkeleton key={index} />)}

                {results &&
                    results.map((props) => (
                        <Resource key={props.id} {...props} category={toolsFilterOption.label} isSelected={false} />
                    ))}
            </S.List>
        </S.CategoryWrapper>
    )
}
