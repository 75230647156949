import { useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { GetToolsParams, GetToolsResponse } from 'api/actions/tools/toolsActions.types'
import { getTools } from 'api/actions/tools/toolsActions'
import { getNextPageParam } from 'utils/getNextPageParam'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

export const useGetToolsInfinite = (params?: GetToolsParams) => {
    const queryInitialPage = useRef(params?.page || 1)

    const query = useInfiniteQuery<GetToolsResponse, AxiosError>({
        queryKey: [Queries.getToolsInfinite, params],
        queryFn: ({ pageParam = queryInitialPage.current }) => getTools({ ...params, page: pageParam }),
        getNextPageParam: ({ next }) => getNextPageParam(next),
        ...preventRefetchQueryOptions,
    })

    return query
}
