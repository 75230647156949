import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { GetTooltipsResponse, VoteTooltipPayload, VoteTooltipResponse } from 'api/actions/tooltips/tooltipActions.types'
import { voteTooltip } from 'api/actions/tooltips/tooltipsActions'
import { Mutations } from 'api/mutations.enum'
import { Queries } from 'api/queries.enum'

export const useVoteTooltip = () => {
    const queryClient = useQueryClient()

    const queryKey = [Queries.getTooltips]

    const mutation = useMutation<VoteTooltipResponse, AxiosError, VoteTooltipPayload, GetTooltipsResponse>({
        mutationKey: [Mutations.voteTooltip],
        mutationFn: voteTooltip,
        onMutate: async ({ id, values }) => {
            await queryClient.cancelQueries({ queryKey })

            const previousTooltips = queryClient.getQueryData<GetTooltipsResponse>(queryKey)

            queryClient.setQueryData<GetTooltipsResponse>(queryKey, (oldTooltips) => {
                if (!oldTooltips) {
                    return
                }

                const newTooltips = oldTooltips.map((item) => {
                    if (item.uuid === id) {
                        return {
                            ...item,
                            liked: values.like,
                        }
                    }

                    return item
                })

                return newTooltips
            })

            if (previousTooltips) {
                return [...previousTooltips]
            }
        },
        onError: (err, newTooltips, context) => {
            queryClient.setQueryData(queryKey, context)
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey })
        },
    })

    return mutation
}
